import { CommonModule } from '@angular/common';
import { Component, Inject, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MAT_SNACK_BAR_DATA, MatSnackBarModule, MatSnackBarRef } from '@angular/material/snack-bar';

export interface SnackBarData {
  title: string;
  message: string;
  icon: string;
  defaultColor?: boolean;
  withBtnClose?: boolean;
  withBtnAccept?: boolean;
}

@Component({
  selector: 'app-snackbar-content-v2',
  standalone: true,
  imports: [
    CommonModule,
    MatSnackBarModule,
    MatButtonModule,
    MatIconModule
  ],
  template: `
    <div class="toast-v2">
      <div class="toast-v2__close">
        <a href="javascript:void(0)" (click)="snackBarRef.dismissWithAction()"><mat-icon>close</mat-icon></a>
      </div>
      <div class="row m-0 gap-10">
        <div class="col-sm-auto p-0">
          <!-- <mat-icon [class.material-symbols-outlined]="data.icon=='check_circle'">{{data.icon}}</mat-icon> -->
          <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
            <g clip-path="url(#clip0_3272_5060)">
            <path d="M36.452 21.9309C36.452 31.9105 28.3612 40.0004 18.382 40.0004C15.9338 40.0004 13.6002 39.5136 11.4716 38.6314C5.65369 36.2214 1.36841 30.8597 0.482483 24.4144C0.369873 23.6027 0.3125 22.7735 0.3125 21.9309C0.3125 11.9507 8.40241 3.86084 18.382 3.86084C28.3612 3.86084 36.452 11.9507 36.452 21.9309Z" fill="#51CA7A"/>
            <path d="M18.3818 3.86084C17.4263 3.86084 16.4885 3.93561 15.5732 4.07843C24.2192 5.42761 30.8351 12.9059 30.8351 21.9309C30.8351 30.9547 24.2198 38.4327 15.5745 39.7825C16.4894 39.9253 17.4266 40.0004 18.3815 40.0004C28.361 40.0004 36.4519 31.9105 36.4519 21.9309C36.4519 11.9507 28.361 3.86084 18.3818 3.86084Z" fill="#4BBA70"/>
            <path d="M38.7079 20.5948L28.3896 27.4273C27.6544 27.9144 26.7639 28.1076 25.8936 27.9684C25.0211 27.8296 24.1284 28.02 23.3914 28.508L22.0105 29.4232L14.2773 18.0239L16.1746 9.82754C16.322 9.18941 16.395 8.54275 16.395 7.89852C16.395 6.23195 15.9076 4.58706 14.9728 3.17501C14.743 2.82772 14.6332 2.43496 14.6332 2.04708C14.6332 1.38424 14.9548 0.733906 15.5493 0.34023C16.3504 -0.190471 17.4121 -0.0918994 18.1018 0.577046L19.3311 1.76754C20.7367 3.13137 21.821 4.79183 22.5018 6.62838L23.1683 8.42281L30.032 3.8879C31.1282 3.16219 32.6111 3.47957 33.3112 4.60232C33.9862 5.68386 33.6395 7.113 32.576 7.81704C32.5632 7.82558 32.5623 7.83932 32.5665 7.84969C32.5726 7.86098 32.5855 7.86861 32.5998 7.86251C32.8711 7.75814 33.1549 7.70748 33.4363 7.70748C33.8855 7.70748 34.3308 7.83687 34.7116 8.08315C34.9741 8.25283 35.2066 8.47835 35.39 8.75545C35.7443 9.29073 35.8499 9.91848 35.734 10.502C35.5521 11.4212 34.884 11.8948 34.7528 11.9839C34.7305 11.9995 34.7244 12.0303 34.74 12.0535C34.74 12.0541 34.7409 12.055 34.7415 12.055C34.7562 12.0758 34.7861 12.0816 34.8074 12.0681C34.9945 12.0227 35.1849 12.001 35.3754 12.001C35.8402 12.001 36.3025 12.1331 36.6956 12.3837C36.9614 12.5537 37.1964 12.7783 37.3782 13.0535C38.0917 14.1308 37.7969 15.5828 36.7184 16.2967C36.6919 16.3147 36.6653 16.3327 36.6388 16.3498C36.6235 16.362 36.619 16.3815 36.6269 16.3952C36.6284 16.3986 36.6321 16.4022 36.6354 16.4038C36.6473 16.4132 36.6663 16.4141 36.6818 16.4022C37.3517 16.174 38.0808 16.2777 38.6673 16.6577C38.9374 16.831 39.1769 17.0645 39.3665 17.3507C40.0803 18.4289 39.7852 19.8809 38.7079 20.5948Z" fill="#FDD7BD"/>
            <path d="M22.109 31.5857L17.756 34.4684L11.4715 38.6301C5.65363 36.2198 1.36835 30.8591 0.482422 24.4132L7.77552 19.5834L12.2152 16.6431C12.7264 16.3043 13.4161 16.445 13.7548 16.9571L22.423 30.0452C22.7618 30.5573 22.6211 31.2457 22.109 31.5857Z" fill="#4C5DC4"/>
            <path d="M22.1089 31.5857L17.7559 34.4684L7.77539 19.5834L12.2151 16.6431C12.7263 16.3043 13.416 16.445 13.7547 16.9571L22.4229 30.0449C22.7617 30.5573 22.621 31.2457 22.1089 31.5857Z" fill="#3B4092"/>
            <path d="M34.7114 8.08386L31.2778 9.92133C30.7908 10.1813 30.1868 10.0455 29.8594 9.60211L32.5663 7.85071C32.5724 7.862 32.5852 7.86963 32.5995 7.86353C32.8709 7.75916 33.1547 7.7085 33.436 7.7085C33.8856 7.7085 34.3305 7.83789 34.7114 8.08386Z" fill="#FAC5AA"/>
            <path d="M36.6955 12.3846L33.3367 14.1806C32.8497 14.4415 32.2457 14.306 31.918 13.8626L34.6249 12.1109L34.7418 12.056C34.7564 12.0767 34.7863 12.0825 34.8077 12.0691C34.9948 12.0236 35.1852 12.002 35.3756 12.002C35.8404 12.002 36.3027 12.1341 36.6955 12.3846Z" fill="#FAC5AA"/>
            <path d="M38.6672 16.6583L35.7088 18.2409C35.2224 18.5007 34.6187 18.3652 34.29 17.9217L36.635 16.4044C36.6472 16.4139 36.6661 16.4148 36.6814 16.4026C37.3513 16.1746 38.0803 16.2784 38.6672 16.6583Z" fill="#FAC5AA"/>
            </g>
            <defs>
            <clipPath id="clip0_3272_5060">
            <rect width="40" height="40" fill="white"/>
            </clipPath>
            </defs>
          </svg>
        </div>
        <div class="col-sm p-0">
          <div class="row m-0 gap-10 justify-content-between">
            <div class="col-sm-auto p-0">
              <div [class]="'toast-title toast-title'+((data.defaultColor!=undefined && data.defaultColor)?'-default':'')">
                <span>{{data.title}}</span>
              </div>
            </div>
          </div>
          <div [class]="'toast-text'+((data.defaultColor!=undefined && data.defaultColor)?'-default':'')" [innerHTML]="data.message"></div><!--{{data.message}}-->
        </div>
      </div>
      <div class="row gap-0" *ngIf="data.withBtnAccept">
        <span class="actions" matSnackBarActions>
          <button mat-flat-button matSnackBarAction (click)="snackBarRef.dismissWithAction()">Aceptar</button>
        </span>
      </div>
    </div>
  `,
  styleUrls: ['./snackbar-content-v2.component.scss']
})
export class SnackbarContentV2Component {
  snackBarRef = inject(MatSnackBarRef);
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: SnackBarData) { }
}

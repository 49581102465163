export const ticketTypeIds: string[] = [
  'CHANGE_REQUEST',
  'RETURN_REQUEST'
];

export enum CustomerSearchType {
  IDENTITY_DOCUMENT = 'IDENTITY_DOCUMENT',
  EMAIL = 'EMAIL',
  ORDER_ID = 'ORDER_ID',
  SALE_NOTE_NUMBER = 'SALE_NOTE_NUMBER',
  REQUEST_NUMBER = 'REQUEST_NUMBER'
}

export enum Role {
  ADMIN = 'ADMIN',
  CORPORATE_ADMIN = 'CORPORATE_ADMIN',
  CORPORATE_SUPERVISOR = 'CORPORATE_SUPERVISOR',
  THIRDPARTY_SUPERVISOR = 'THIRDPARTY_SUPERVISOR',
  CORPORATE_AGENT = 'CORPORATE_AGENT',
  THIRDPARTY_AGENT = 'THIRDPARTY_AGENT',
  THIRDPARTY_ADMIN = 'THIRDPARTY_ADMIN',
  SHOPSTAR_AGENT = 'SHOPSTAR_AGENT',
  SHOPSTAR_SUPERVISOR = 'SHOPSTAR_SUPERVISOR'
}

export enum RuleGroup {
  ECOMMERCE_ORDERS = 'ECOMMERCE_ORDERS'
}

export enum SourceChannel {
  REX = 'REX', 
  MY_ORDERS = 'MY_ORDERS'
}

export const regexTypeData : Map<string, RegExp> = new Map([
  ['email', new RegExp(/^[a-zA-Z0-9][\w\.-]*[a-zA-Z0-9]@[a-zA-Z0-9][\w\.-]*[a-zA-Z0-9]\.[a-zA-Z]{2,3}?$/)],
  ['id', new RegExp(/^[A-Za-z0-9]{8,12}$/)],//dni|pasaporte|ruc(11)-carnet de extranjeria(12)
  ['request', new RegExp(/^(PVEA|VIV|RPLAZA|HPSA|TPSA|AGORA|MKR|AGORA_SHOP|JOKR|SHPSTAR)-(CD|ST)-[A-Za-z0-9]{8,12}-[0-9]+$/)],
  ['order', new RegExp(/^((\d{13}-\d{2})|(\d{15})|(v\d+viv-\d{2})|(v\d+plzv-\d{2}|(v\d+pvqa-\d{2})|(v\d+vivqa-\d{2})))?$/)],
  ['sale-note', new RegExp(/^\d{13}?$/)]
]);

export const searchTypeMap: Map<string, CustomerSearchType> = new Map([
  ['email', CustomerSearchType.EMAIL],
  ['id', CustomerSearchType.IDENTITY_DOCUMENT],
  ['request', CustomerSearchType.REQUEST_NUMBER],
  ['order', CustomerSearchType.ORDER_ID],   
  ['sale-note', CustomerSearchType.SALE_NOTE_NUMBER],
]);

export const searchRoleMap: Map<string, string> = new Map([
  [ Role.ADMIN, 'Administrador Customer Care' ],
  [ Role.CORPORATE_ADMIN, 'Administrador' ],
  [ Role.THIRDPARTY_ADMIN, 'Administrador externo' ],
  [ Role.CORPORATE_SUPERVISOR, 'Supervisor' ],
  [ Role.THIRDPARTY_SUPERVISOR, 'Supervisor externo' ],
  [ Role.SHOPSTAR_SUPERVISOR, 'Supervisor Shopstar' ],
  [ Role.CORPORATE_AGENT, 'Gestor' ],
  [ Role.THIRDPARTY_AGENT, 'Gestor externo' ],
  [ Role.SHOPSTAR_AGENT, 'Gestor Shopstar' ]
]);

export enum CatalogType {
  TENANTS = "TENANTS",
  TICKET_TYPE = "TICKET_TYPE",
  REASONS = "REASONS",
  RESPONSIBILITY = "RESPONSIBILITY",
  DELIVERY_STORE = "DELIVERY_STORE",
  STORES = 'STORES',
  STORE_TYPES = 'STORE_TYPES',
  STATES = 'STATES',
  UBIGEO = 'UBIGEO',
  ACTION = 'ACTION',
  ACCOUNTS = 'ACCOUNTS',
  TICKET_AREA = 'TICKET_AREA',
  TICKET_SUB_AREA = 'TICKET_SUB_AREA',

}

export const requestActionResponseMessage: any[] = [
  { requestActionId: 'COMMENT',  responseMessage: 'Se ha añadido un <strong>comentario</strong> exitosamente.' },
  { requestActionId: 'RECONTACT',  responseMessage: 'Se ha añadido un <strong>recontacto</strong> exitosamente.' },
  { requestActionId: 'STATUS',  responseMessage: 'Se ha actualizado el <strong>cambio de estado</strong> exitosamente.' },
  { requestActionId: 'PRIORITIZE',  responseMessage: 'Se ha actualizado el <strong>cambio de priorización</strong> exitosamente.' },
  { requestActionId: 'REASSIGN',  responseMessage: 'La solicitud ha sido <strong>reasignada</strong> exitosamente.' },
  { requestActionId: 'APPROVE',  responseMessage: 'La solicitud se ha <strong>aprobado</strong> exitosamente.' },
  { requestActionId: 'REJECT',  responseMessage: 'La solicitud se ha <strong>rechazado</strong> exitosamente.' },
  { requestActionId: 'EDIT',  responseMessage: 'La solicitud se ha <strong>editado</strong> exitosamente.' },
];

export enum ManagementMode {
  HOME_PICKUP = "HOME_PICKUP",
  DELIVERY_IN_STORE = "DELIVERY_IN_STORE"
}

export enum RequestLabels {
  OPEN_PACKAGE = "Empaque abierto",
  CLOSED_PACKAGE = "Empaque cerrado"
}

export enum RequestStatus {
  PENDING_PHOTOS = 1,
  PENDING = 2,
  IN_REVIEW = 3,
  FINISHED = 4,
  CANCELLED = 5
}

export enum OrderByEnum {
  SELLTENANTID = "sellTenantId",
  DISPATCHTENANTID = "dispatchTenantId",
  REQUESTREASONID = "requestReasonId",
  CUSTOMERDOCUMENT = "customerDocument",
  CREATEDAT = "createdAt",
  TIMESTARTEDAT = "timeStartedAt"
}

export enum SortOrderEnum {
  DESC = "DESC",
  ASC = "ASC",
}

export const allowed_tenants: string[] = [
  'HPSA',
  'TPSA',
  'PVEA',
  'RPLAZA',
  'SELLER_CENTER'
];

const tenantNames = new Map([
  ['HPSA', 'Promart'],
  ['TPSA', 'Oechsle'],
  ['PVEA', 'plazaVea'],
  ['AGORA', 'Agora.pe'],
  ['RPLAZA', 'Real Plaza'],
  ['AGORA_SHOP', 'Agora Shop'],
  ['SHPSTAR', 'Shopstar'],
  ['MKR', 'Makro'],
  ['JOKR', 'Jokr'],
])

const sellerNames = new Map([
  ['Promart', 'HPSA'],
  ['Oechsle', 'TPSA'],
  ['plazaVea', 'PVEA'],
  ['Vivanda', 'VIV'],
  ['Makro', 'MKR'],
  ['Jokr', 'JOKR']
])

const slaStatusNames = new Map([
  ['ON_TIME', 'A tiempo'],
  ['ABOUT_EXPIRE', 'Por vencer'],
  ['EXPIRED', 'Vencido']
])

const requestStatus = new Map([
  [1, 'PENDING_PHOTOS'],
  [2, 'PENDING'],
  [3, 'IN_REVIEW'],
  [4, 'COMPLETED'],
  [5, 'CANCELLED'],
])

export const reasonResponsibleMap: Map<string, string> = new Map([
  [ 'COMPANY', 'Empresa' ],
  [ 'CUSTOMER', 'Cliente' ]
]);

export const prioritiesMap: Map<string, string> = new Map([
  [ 'LOW', 'Media' ],
  [ 'HIGH', 'Alta' ],
  [ 'CRITICAL', 'Muy alta' ]
]);

export const approveRejectMap: Map<string, string> = new Map([
  [ 'APPROVE', 'Aprobado' ],
  [ 'REJECT', 'Rechazado' ]
]);

export const managementModeMap: Map<string, string> = new Map([
  [ ManagementMode.HOME_PICKUP, 'Recolección a domicilio' ],
  [ ManagementMode.DELIVERY_IN_STORE, 'Entrega en tienda' ]
]);

export const responsibilities: any[] = [
  { responsibilityId: 'COMPANY',  responsibilityName: 'Empresa' },
  { responsibilityId: 'CUSTOMER',  responsibilityName: 'Cliente' }
];

export const packageStatuses: any[] = [
  { packageStatusId: 'OPEN_PACKAGE',  packageStatusName: RequestLabels.OPEN_PACKAGE },
  { packageStatusId: 'CLOSED_PACKAGE',  packageStatusName: RequestLabels.CLOSED_PACKAGE }
];

export const slaStatuses: any[] = [
  { slaStatusId: 'ON_TIME',  slaStatusName: 'A tiempo' },
  { slaStatusId: 'ABOUT_EXPIRE',  slaStatusName: 'Por vencer' },
  { slaStatusId: 'EXPIRED',  slaStatusName: 'Vencido' }
];

export const dateTypes: any[] = [
  { dateTypeId: 'REASSIGNED_AT',  dateTypeName: 'Fecha de asignación' },
  { dateTypeId: 'CREATED_AT',  dateTypeName: 'Fecha de creación' }
];

export const priorities: any[] = [
  { priorityId: 'LOW',  priorityName: 'Media', priorityIconName: 'horizontal_rule' },
  { priorityId: 'HIGH',  priorityName: 'Alta', priorityIconName: 'keyboard_arrow_up'},
  { priorityId: 'CRITICAL',  priorityName: 'Muy alta', priorityIconName: 'keyboard_double_arrow_up' }
];

export const recontacts: any[] = [
  { recontactId: { recontactType: 'NUMBER', lowerLimit: 0 },  recontactName: 'Sin recontactos' },
  { recontactId: { recontactType: 'RANGE', lowerLimit: 1, upperLimit: 3 },  recontactName: 'De 1 a 3 recontactos' },
  { recontactId: { recontactType: 'RANGE', lowerLimit: 4, upperLimit: 6 },  recontactName: 'De 4 a 6 recontactos' },
  { recontactId: { recontactType: 'RANGE', lowerLimit: 7, upperLimit: 9 },  recontactName: 'De 7 a 9 recontactos' },
  { recontactId: { recontactType: 'UNBOUNDED_RANGE', lowerLimit: 10 },  recontactName: 'De 10 a más recontactos' },
];

export const labels: any[] = [
  { labelId: 'Empaque cerrado',  labelName: 'Empaque cerrado' },
  { labelId: 'Empaque abierto',  labelName: 'Empaque abierto' },
  { labelId: 'Producto vencido',  labelName: 'Producto vencido' },
  { labelId: 'Calidad de producto',  labelName: 'Calidad de producto' }
];

export const endingTypesMap: Map<string, string> = new Map([
  [ 'CHANGE', 'Cambio' ],
  [ 'CASH_BACK', 'Devolución de dinero' ],
  [ 'ABSENT_CUSTOMER', 'Cliente ausente' ],
  [ 'NOT_CORRESPOND', 'No corresponde' ],
  [ 'NOT_COMPLY_WITH_TC', 'No cumple los términos y condiciones' ],
]);

export const endingTypes: any[] = [
  {endingId: 'CHANGE', endingName: 'Cambio'},
  {endingId: 'CASH_BACK', endingName: 'Devolución de dinero'},
  {endingId: 'ABSENT_CUSTOMER', endingName: 'Cliente ausente'},
  {endingId: 'NOT_CORRESPOND', endingName: 'No corresponde'},
  {endingId: 'NOT_COMPLY_WITH_TC', endingName: 'No cumple los términos y condiciones'},
]

export const rejectReasons: any[] = [
  {rejectionCode: 'NOT_ENOUGH_EVIDENCE', rejectionDescription: 'Las evidencias enviadas fueron insuficientes'},
  {rejectionCode: 'PRODUCT_WAS_OPENED', rejectionDescription: 'El producto se encontraba abierto'},
  {rejectionCode: 'PRODUCT_MANIPULATED_FOR_OTHER_PURPOSES', rejectionDescription: 'Producto se encontraba manipulado para otros fines'},
  {rejectionCode: 'REQUEST_NOT_APPLICABLE_TO_CHANGES_AND_RETURNS', rejectionDescription: 'La solicitud no corresponde a cambios y devoluciones'},
  {rejectionCode: 'REQUEST_NOT_APPLICABLE_DUE_TO_CHANGE_OF_MIND', rejectionDescription: 'La solicitud no aplica por cambio de opinión'},
]

export const reassignReasons: any[] = [
  {reassignReasonId: 'CUSTOMER_HOME_PICKUP', reassignReasonName: 'Recolección a domicilio del cliente'},
  {reassignReasonId: 'REFUND_REQUEST', reassignReasonName: 'Solicitud de devolución de dinero'},
  {reassignReasonId: 'AREA_REASSIGN', reassignReasonName: 'Reasignación a otra área '},
  {reassignReasonId: 'WRONG_ASSIGNMENT', reassignReasonName: 'Asignación errada'}
]

export const commons = {
  ticketTypeIds: ticketTypeIds,
  regexTypeData: regexTypeData,
  searchTypeMap: searchTypeMap,
  searchRoleMap: searchRoleMap,
  reasonResponsibleMap: reasonResponsibleMap,
  managementModeMap,
  tenantNames,
  sellerNames,
  slaStatusNames,
  requestStatus,
  responsibilities,
  packageStatuses,
  slaStatuses,
  dateTypes,
  priorities,
  recontacts,
  labels,
  endingTypes,
  rejectReasons,
  reassignReasons
}

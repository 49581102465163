import { Component } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  template: `<router-outlet></router-outlet>`
})
export class AppComponent {

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    this.matIconRegistry.addSvgIcon(
      "auth-microsoft",
      this.domSanitizer.bypassSecurityTrustResourceUrl("./assets/icons/auth-microsoft.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "rexRequestNumber",
      this.domSanitizer.bypassSecurityTrustResourceUrl("./assets/icons/rex-request-number.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "rexDNISearch",
      this.domSanitizer.bypassSecurityTrustResourceUrl("./assets/icons/rex-dni-search.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "rexOrderId",
      this.domSanitizer.bypassSecurityTrustResourceUrl("./assets/icons/rex-order-id.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "rexSaleNote",
      this.domSanitizer.bypassSecurityTrustResourceUrl("./assets/icons/rex-sale-note.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "rexEmail",
      this.domSanitizer.bypassSecurityTrustResourceUrl("./assets/icons/rex-email.svg"),
    );
    this.matIconRegistry.addSvgIcon(
      "formSearchError",
      this.domSanitizer.bypassSecurityTrustResourceUrl("./assets/icons/rex-search-error.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "formSearchError",
      this.domSanitizer.bypassSecurityTrustResourceUrl("./assets/icons/rex-search-error.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "rexNetworkError",
      this.domSanitizer.bypassSecurityTrustResourceUrl("./assets/icons/icon-network-error.svg"),
    );
    this.matIconRegistry.addSvgIcon(
      "rexSearch",
      this.domSanitizer.bypassSecurityTrustResourceUrl("./assets/icons/icon-search.svg"),
    );
    this.matIconRegistry.addSvgIcon(
      "rexSearchError",
      this.domSanitizer.bypassSecurityTrustResourceUrl("./assets/icons/icon-search-error.svg"),
    );
    this.matIconRegistry.addSvgIcon(
      "rexSelectItem",
      this.domSanitizer.bypassSecurityTrustResourceUrl("./assets/icons/icon-select-item.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "circle",
      this.domSanitizer.bypassSecurityTrustResourceUrl("./assets/icons/circle.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "CHANGE_REQUEST",
      this.domSanitizer.bypassSecurityTrustResourceUrl("./assets/icons/icon-change.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "RETURN_REQUEST",
      this.domSanitizer.bypassSecurityTrustResourceUrl("./assets/icons/icon-return.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "timerSla",
      this.domSanitizer.bypassSecurityTrustResourceUrl("./assets/icons/icon-timer.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "rexPositiveVote",
      this.domSanitizer.bypassSecurityTrustResourceUrl("./assets/icons/icon-positive-vote.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "rexClick",
      this.domSanitizer.bypassSecurityTrustResourceUrl("./assets/icons/icon-click.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "rexError",
      this.domSanitizer.bypassSecurityTrustResourceUrl("./assets/icons/icon-error.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "LOW",
      this.domSanitizer.bypassSecurityTrustResourceUrl("./assets/icons/icon-priority-low.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "HIGH",
      this.domSanitizer.bypassSecurityTrustResourceUrl("./assets/icons/icon-priority-high.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "CRITICAL",
      this.domSanitizer.bypassSecurityTrustResourceUrl("./assets/icons/icon-priority-critical.svg")
    );
  }

}
